import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Model from 'models/Model';
import ModelUtils from 'models/Model.utils';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  model: Model;

  className?: string;
};

export default function ModelPhotoSets({ model, className }: Props) {
  if (!model.photoSets || model.photoSets.length === 0) {
    return null;
  }

  return (
    <div className={GenericUtils.classNames('model-photo-sets', className)}>
      <h2 className="section__title">Premium content by {model.username}</h2>
      <p>Want to be a {model.username}'s true fan? Signup and join her Fan Club to enjoy some Premium Content.<br/>Yes, we're talking about some <u>hot photos and videos</u> from {model.username} made from you.</p>
      <p className="text--primary"><b>{model.username}'s photos and videos</b></p>

      <div className="model-photo-sets__container">
      {
        model.photoSets.map(pS => (
          <a key={pS.id} href={ModelUtils.getModelPhotoSetUrl(model, pS)} target="_blank" className="model-photo-sets__item">
            <div className="model-photo-sets__item__cover-container">
              <img src={pS.coverUrl} className="model-photo-sets__item__cover" />
              {
                pS.fanClubOnly ? pS.label && <span className="model-photo-sets__item__label">🔒 Fan Club Exclusive</span> :
                  (pS.cost !== undefined && pS.cost !== null) ? <span className="model-photo-sets__item__label">🔒 Unlock it {pS.cost} token</span> :
                    <span className="model-photo-sets__item__label">😍 Free</span>
              }
              {pS.isVideo === true ? <FontAwesomeIcon icon={['fas', 'video']} className="model-photo-sets__item__icon" /> : <FontAwesomeIcon icon={['fas', 'images']} className="model-photo-sets__item__icon" />}
            </div>

            {pS.name && <span className="model-photo-sets__item__name">{pS.name}</span>}
          </a>
        ))
      }
      </div>
    </div>
  );
}
