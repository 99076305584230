import * as React from 'react';
import Helmet from 'react-helmet';
import urljoin from 'url-join';
import Model from 'models/Model';
import ModelUtils from 'models/Model.utils';
import PageUtils from 'utils/PageUtils';
import Config from 'config/Config';

type Props = {
  model: Model;
};

type Schema = {
  '@context'?: string;
  '@type'?: string;

  mainEntityOfPage?: {
    '@type'?: string;
    '@id'?: string;
  };

  author?: {
    '@type'?: string;
    name?: string;
  };

  publisher?: {
    '@type'?: string;
    name?: string;
    image?: {
      '@type'?: 'ImageObject';
      url?: string;
    };
    logo?: {
      '@type'?: 'ImageObject';
      url?: string;
    };
  };

  url?: string;
  name?: string;
  description?: string;
  headline?: string;
  alternateName?: string;
  datePublished?: string;
  dateModified?: string;

  image?: {
    '@type'?: string;
    url?: string;
  };

  itemListElement?: {
    '@type'?: string;
    position?: number;
    item?: {
      '@id'?: string;
      name?: string;
      image?: string;
    };
  }[];
};

export default class ModelRoomSeoHelmet extends React.Component<Props> {

  render() {
    const { model } = this.props;
    let title = PageUtils.generateTitle(model.username!);
    let description = PageUtils.generateModelRoomDescription(model);
    let image = ModelUtils.getRoomImageUrl(model);
    if (!image.startsWith('http')) {
      image = urljoin(Config.SiteUrl, image);
    }

    let url = urljoin(Config.SiteUrl, Config.PathPrefix);

    const siteUrl = urljoin(Config.SiteUrl, Config.PathPrefix);
    const schemas: Schema[] = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: siteUrl,
        name: title,
        alternateName: Config.SiteAltTitle ? Config.SiteAltTitle : '',
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': url,
              name: title,
              image,
            },
          },
        ],
      },
    ];

    return (
      <Helmet>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        <script type="application/ld+json">{JSON.stringify(schemas)}</script>

        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={Config.TwitterUser} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <meta name="p:domain_verify" content={Config.PinterestClaim}/>
      </Helmet>
    );
  }

}
