import * as React from 'react';
import RouteUtils from 'utils/RouteUtils';

type Props = {
  tags: string[];
  className?: string;
};

export default class TagList extends React.Component<Props> {
  render() {
    const { tags, className } = this.props;

    return (
      <div className={className ? `tag-list ${className}` : `tag-list`}>
        {tags.map(tag => 
          <a 
            key={tag} 
            href={RouteUtils.generatePathToCategory(tag)}
            target="_blank"
            title={`Category ${tag}`}
            className="tag-list__item tag-list__item--clickable"
          >
              {tag}
          </a>
        )}
      </div>
    );
  }
}
