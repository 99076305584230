import * as React from 'react';
import TagList from 'components/TagList';
import ModelImage from 'components/ModelImage';
import Model from 'models/Model';
import ModelUtils from 'models/Model.utils';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  model: Model;

  className?: string;
};

export default class ModelHeader extends React.Component<Props> {
  render() {
    const { model, className } = this.props;

    return (
      <header className={GenericUtils.classNames('model-header', className)}>
        <div className="model-header__container">
          <div className="model-header__info-container">
            <h1 className="section__title">
              {model.username}
            </h1>

            {model.boobsSize && (
              <p className="model-header__info">
                Boobs size: {model.boobsSize}
              </p>
            )}

            {model.babepediaUsername && (
              <p className="model-header__info">
                Babepedia:{' '}
                <a
                  title={`${model.username}'s Babepedia page`}
                  href={ModelUtils.getModelBabepediaUrl(model)}
                  target="_black"
                >
                  {model.babepediaUsername}
                </a>
              </p>
            )}
            {model.twitterUsername && (
              <p className="model-header__info">
                Twitter:{' '}
                <a
                  title={`${model.username}'s Twitter profile`}
                  href={ModelUtils.getModelTwitterUrl(model)}
                  target="_black"
                >
                  {model.twitterUsername}
                </a>
              </p>
            )}
            {model.instagramUsername && (
              <p className="model-header__info">
                Instagram:{' '}
                <a
                  title={`${model.username}'s Instagram profile`}
                  href={ModelUtils.getModelInstagramUrl(model)}
                  target="_black"
                >
                  {model.instagramUsername}
                </a>
              </p>
            )}

            {model.tags && <TagList tags={model.tags}/>}
          </div>
        </div>
      </header>
    );
  }
}
