import * as React from 'react';
import LoadingView from 'components/LoadingView';
import ModelService from 'http/services/ModelService';
import Model from 'models/Model';
import ModelUtils from 'models/Model.utils';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  model: Model;
  className?: string;
};

type State = {
  model: Model;
  loading: boolean;
};

export default class ModelRoomFrame extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      model: props.model,
      loading: true,
    };
  }

  componentDidMount() {
    this.isModelOnline();
  }

  private isModelOnline = async () => {
    this.setState({
      loading: true,
    });

    let event = {
      'event': 'model-frame-loaded',
      'isOnline': false,
      'modelUsername': this.props.model.username!,
    };

    try {
      const model = await ModelService.isModelOnline(this.props.model.username!);

      event.isOnline = true;

      this.setState((state: State) => ({
        model: {
          ...state.model,
          ...model,
          isOnline: true,
        },
      }));
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        loading: false,
      });

      GenericUtils.sendGTMEvent(event);
    }
  };

  render() {
    const { model } = this.state;
    const { className } = this.props;

    return (
      <div className={GenericUtils.classNames('model-room-frame__root', className)}>
        <div className="model-room-frame__frame-container">
          {
            this.state.loading ? <LoadingView position="absolute" message="Knocking on model's room..." /> :
            model.isOnline ? (
              <>
                <iframe
                  src={ModelUtils.getRoomFullVideoUrl(model, 'website-model-room-frame', GenericUtils.isMobile())}
                  frameBorder={0}
                  scrolling="no"
                  className="model-room-frame__frame"
                />

              </>
            ) : (
                <div className="model-room-frame__offline-container">
                  <p className="model-room-frame__offline-text">{model.username} is currently offline 😔</p>
                  <p className="model-room-frame__offline-text">Do you want to know when {model.username} will be back online?</p>
                  <a href="https://twitter.com/BTWG4" target="_blank" className="button button--outlined model-room-frame__offline-button">Follow us on Twitter</a>
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
