import React from 'react';
import Helmet from 'react-helmet';
import Master from 'layouts/Master';
import ModelRoomSeoHelmet from 'components/Seo/ModelRoomSeoHelmet';
import ModelHeader from 'components/ModelHeader';
import ModelRoomFrame from 'components/ModelRoomFrame';
import ModelList from 'components/ModelList';
import ModelPhotoSets from 'components/ModelPhotoSets';
import LoadingView from 'components/LoadingView';
import Model from 'models/Model';
import ModelUtils from 'models/Model.utils';
import useModelList from 'hooks/useModelList';
import PageUtils from 'utils/PageUtils';

type Props = {
  pageContext: {
    model: Model;
    similarModels: Model[];
  };
};

export default function ModelRoom({pageContext}: Props) {
  const {model, similarModels} = pageContext;

  const { models, isLoading } = useModelList(similarModels || []);

  return (
    <Master mainClassName="model-room__main" footerClassName="model-room__footer">
      <Helmet>
        <title>
          {PageUtils.generateTitle(`Camgirl ${model.username!}`)}
        </title>
      </Helmet>
      <ModelRoomSeoHelmet model={model}/>

      <div className="model-room__container">

        <div className="model-room__model-container">

          <ModelRoomFrame model={model} className="model-room__frame"/>

          <div className="model-room__buttons-container">
            <a
              id="join-button"
              title={`Chat with ${model.username} for Free`}
              href={ModelUtils.getSignupUrl(model)}
              rel="nofollow"
              target="_blank"
              className="model-room__button model-room__button--pink heart-beat"
            >
              <span className="model-room__button__primary-text">
                CHAT FOR FREE
              </span>
              <br/>
              <span className="model-room__button__secondary-text">
                No Email or CC Required
              </span>
            </a>

            {
              (model.hasFanClub && model.fanClubUrl) && (
                <a
                  id="fan-club-button"
                  title={`Join ${model.username}'s Fan Club`}
                  href={ModelUtils.getFanClubUrl(model)}
                  rel="nofollow"
                  target="_blank"
                  className="model-room__button model-room__button--pink--outlined"
                >
                  <span className="model-room__button__primary-text">
                    {`Join ${model.username}'s Fan Club`}
                  </span>
                  <br/>
                  <span className="model-room__button__secondary-text">
                    {model.fanClubCost} Token
                  </span>
                </a>
              )
            }
          </div>

          <ModelHeader
            model={model}
            className="model-room__header"
          />

          <ModelPhotoSets
            model={model}
            className="model-room__photo-sets"
          />
        </div>

        <div className="model-room__related-models__container">
          <h2 className="section__title model-room__related-models__title">
            Related camgirls
          </h2>

          {
            isLoading
              ? <LoadingView message="Loading related camgirls..."  position="relative"/>
              : <ModelList models={models} sortBy="online" className="model-room__related-models-list"/>
          }
        </div>

      </div>
    </Master>
  );
}
